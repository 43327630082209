import React from "react";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageComponent = ({ className, src, image, style, onClick, objectPosition="50% 50%" }) => {
    const allClassNames = classNames(className, "image");
    if(image) {
        return <GatsbyImage className={allClassNames} image={getImage(image)} style={style} onClick={onClick} placeholder="blurred" objectPosition={objectPosition}/>;
    } else {
        return  <img className={allClassNames} src={src} onClick={onClick}/>;
    }
}

export default ImageComponent;