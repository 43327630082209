import styled from "styled-components";

import { Flex } from "../Grid";

const SocialLinks = styled(Flex)`
  @media screen and (max-width: 400px) {
    a {
      max-width: 14vw;
      max-height: 14vw;
      svg {
        max-height: 14vw;
        max-width: 14vw;
      }
    }
  }
`;

SocialLinks.displayName = `SocialLinks`;

SocialLinks.defaultProps = {};

export { SocialLinks };
