import React from "react";
// "#1877f2"
export const FacebookIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
      <path className="bg" fill-rule="evenodd" clip-rule="evenodd" d="M48 24.3301C48 11.0752 37.2548 0.330078 24 0.330078C10.7452 0.330078 0 11.0752 0 24.3301C0 36.3092 8.77645 46.238 20.25 48.0385V31.2676H14.1562V24.3301H20.25V19.0426C20.25 13.0276 23.833 9.70508 29.3152 9.70508C31.941 9.70508 34.6875 10.1738 34.6875 10.1738V16.0801H31.6612C28.6798 16.0801 27.75 17.9301 27.75 19.8281V24.3301H34.4062L33.3422 31.2676H27.75V48.0385C39.2235 46.238 48 36.3092 48 24.3301Z" fill="#1877F2"/>
      <path className="inside" fill-rule="evenodd" clip-rule="evenodd" d="M33.3422 31.2676L34.4062 24.3301H27.75V19.8281C27.75 17.9301 28.6798 16.0801 31.6612 16.0801H34.6875V10.1738C34.6875 10.1738 31.941 9.70508 29.3152 9.70508C23.833 9.70508 20.25 13.0276 20.25 19.0426V24.3301H14.1562V31.2676H20.25V48.0385C21.4719 48.2302 22.7242 48.3301 24 48.3301C25.2758 48.3301 26.5281 48.2302 27.75 48.0385V31.2676H33.3422Z" fill="white"/>
    </svg>
  );
};
