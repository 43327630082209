import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { space } from "styled-system";
import { Link } from "gatsby";

const NavLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  color: ${themeGet("colors.white.100")};
  font-weight: ${themeGet("fontWeights.bold")};
  text-decoration: none;
  font-size: ${themeGet("fontSizes.2")};
  transition: color ${themeGet("times.transition")};

  ${space}

  &:hover {
    color: ${themeGet("colors.EYYellow.default")};
    transition: color ${themeGet("times.transition")};
  }

  ${props =>
    props.active &&
    `&:before {
      content: "";
      position: absolute;
      top: 0;
      left: ${props.mobile ? "-30px" : "0"};
      right: 0;
      height: ${props.mobile ? "100%" : "3px"};
      width: ${props.mobile ? "3px" : "100%"};
      background-color: ${props.theme.colors.EYYellow.default};
    };
  }`}

  ${props =>
    props.mobile &&
    `
    justify-content: flex-start;
    border-bottom: 1px solid ${props.theme.colors.EYGrey01};
    padding: 0;
    margin: 0 30px;
    font-size: 24px;
    height: 90px;
  `}
`;

NavLink.defaultProps = {
  py: 0,
  pl: "10px",
  pr: "10px",
  my: 0,
  ml: "20px",
  mr: "20px",
  active: false,
  mobile: false,
};

export { NavLink };
