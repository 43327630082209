import styled from "styled-components";
import { space, color, layout, typography, border } from "styled-system";
import propTypes from "@styled-system/prop-types";

const Text = styled.p`
  margin: 0;
  padding: 0;
  
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  
`;

Text.displayName = `Text`;

Text.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.border,
};

export { Text };
