import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "../../style/GlobalStyles";

import { theme } from "../../style/theme";

import NavBar from "../NavBar";
import Footer from "../Footer";
import Cookies from "../Cookies/Cookies";

const fonts = `
  @font-face {
    font-family: "EYInterstate";
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/EYInterstateRegular.woff") format("woff"), url("/fonts/EYInterstateRegular.woff2") format("woff2");
  }

  @font-face {
    font-family: "EYInterstate";
    font-style: normal;
    font-weight: 300;
    src: url("/fonts/EYInterstateLight.woff") format("woff"), url("/fonts/EYInterstateLight.woff2") format("woff2");
  }

  @font-face {
    font-family: "EYInterstate";
    font-style: normal;
    font-weight: 800;
    src: url("/fonts/EYInterstateBold.woff") format("woff"), url("/fonts/EYInterstateBold.woff2") format("woff2");
  }
`;

const Layout = ({ location, menuInverse = false, hideFooterMobile = false, children }) => {

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Helmet>
            <html lang="en" />
            <title>{data.site.siteMetadata.title}</title>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/img/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              href="/img/favicon-32x32.png"
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href="/img/favicon-16x16.png"
              sizes="16x16"
            />
            <link
              rel="mask-icon"
              href="/img/safari-pinned-tab.svg"
              color="#ff4400"
            />
            <meta name="theme-color" content="#fff" />
            <meta property="og:type" content="business.business" />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta property="og:url" content={"https://cestasey.cz" + location.pathname} />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no shrink-to-fit=no"
            />

            <style>{fonts}</style>
          </Helmet>
          <NavBar location={location} menuInverse={menuInverse} />
          {children}
          <Footer hideFooterMobile={hideFooterMobile} />
          {/*<Cookies />*/}
        </ThemeProvider>
      )}
    />
  );
};

export default Layout;
