import styled from "styled-components";
import Flex from "./Flex";

const Wrapper = styled(Flex)``;

Wrapper.displayName = `Wrapper`;

Wrapper.defaultProps = {
  width: "100%",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
};

export default Wrapper;
