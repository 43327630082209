import React from "react";
import { FacebookIcon } from "./FacebookIcon";
import { InstagramIcon } from "./InstagramIcon";
import { TwitterIcon } from "./TwitterIcon";
import { YouTubeIcon } from "./YouTubeIcon";
import { LinkedInIcon, LinkedInIcon2 } from "./LinkedInIcon";
import { WhatsAppIcon, WhatsAppIcon2 } from "./WhatsAppIcon";
import { ArrowLeftIcon, ArrowRightIcon } from "./Arrow";
import { Group } from "./Group";
import { TriangleBottom } from "./Triangle";
import { PlusIcon } from "./Plus";
import { MinusIcon } from "./Minus";

const Icon = (props) => {
  switch (props.name) {
    case "arrowLeft":
      return <ArrowLeftIcon {...props} />;
    case "arrowRight":
      return <ArrowRightIcon {...props} />;
    case "facebook":
      return <FacebookIcon {...props} fill="" />;
    case "group":
      return <Group {...props} />;
    case "instagram":
      return <InstagramIcon {...props} />;
    case "twitter":
      return <TwitterIcon {...props} />;
    case "youtube":
      return <YouTubeIcon {...props} />;
    case "linkedin":
      return <LinkedInIcon {...props} />;
    case "linkedin2":
      return <LinkedInIcon2 {...props} />;
    case "minus":
      return <MinusIcon {...props} />;
    case "plus":
      return <PlusIcon {...props} />;
    case "triangleBottom":
      return <TriangleBottom {...props} />;
    case "whatsapp":
      return <WhatsAppIcon {...props} />;
    case "whatsapp2":
      return <WhatsAppIcon2 {...props} />;
    default:
      return <div />;
  }
};

export default Icon;
