import styled from "styled-components";
import { space, color, layout, typography, flexbox } from "styled-system";
import propTypes from "@styled-system/prop-types";

import themeGet from "@styled-system/theme-get";

export default Component => {
  let styleFn = null;
  if (Component === "a") {
    styleFn = styled.a;
  } else {
    styleFn = styled(Component);
  }

  const Link = styleFn`
    text-decoration: ${props => (props.underline ? "underline" : "none")};
    color: ${props =>
      props.white
        ? themeGet("colors.white.100")
        : themeGet("colors.EYOffBlack.default")};

    ${space}
    ${color}
    ${layout}
    ${flexbox}
    ${typography}

    svg {
      border-radius: 50%;
      transition: all .3s;
      path {
        transition: all .3s;
      }
     }

    &:hover {
      text-decoration: ${props => (props.underline ? "none" : "underline")};
      color: ${props =>
        props.white
          ? themeGet("colors.white.100")
          : themeGet("colors.EYOffBlack.default")};

      svg {
        background-color: yellow;
        path {
          fill: ${themeGet("colors.EYOffBlack.default")};
          &.inside {
            fill: ${themeGet("colors.EYYellow.default")};
          }
        }
      }
    }

    &:active {
      color: ${props =>
    props.white
      ? themeGet("colors.white.100")
      : themeGet("colors.EYOffBlack.default")};
    }
  `;

  Link.displayName = `Link`;

  Link.defaultProps = {
    underline: false,
    fontSize: "2",
  };

  Link.propTypes = {
    ...propTypes.space,
    ...propTypes.color,
    ...propTypes.layout,
    ...propTypes.typography,
  };

  return Link;
};
