import React from "react";

export const Group = (props) => {
  return (
    <svg
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.09953 6.61957C4.97214 6.61957 5.67953 5.93457 5.67953 5.08957C5.67953 4.24457 4.97214 3.55957 4.09953 3.55957C3.22692 3.55957 2.51953 4.24457 2.51953 5.08957C2.51953 5.93457 3.22692 6.61957 4.09953 6.61957Z"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M5.49 8.06955H3.18C2.61147 8.06152 2.06291 8.27902 1.65436 8.67446C1.2458 9.06989 1.01052 9.61106 1 10.1796V14.9996H3.06V20.4796"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M19.9003 6.61957C20.7729 6.61957 21.4803 5.93457 21.4803 5.08957C21.4803 4.24457 20.7729 3.55957 19.9003 3.55957C19.0277 3.55957 18.3203 4.24457 18.3203 5.08957C18.3203 5.93457 19.0277 6.61957 19.9003 6.61957Z"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M18.5098 8.06955H20.8198C21.3883 8.06152 21.9369 8.27902 22.3454 8.67446C22.754 9.06989 22.9892 9.61106 22.9998 10.1796V14.9996H20.9398V20.4796"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M12.1599 5.82C13.5351 5.82 14.6499 4.74101 14.6499 3.41C14.6499 2.07899 13.5351 1 12.1599 1C10.7847 1 9.66992 2.07899 9.66992 3.41C9.66992 4.74101 10.7847 5.82 12.1599 5.82Z"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M15.27 22.91V15.5H17.27V9.80996C17.2648 9.46548 17.1915 9.12543 17.0542 8.80944C16.917 8.49344 16.7185 8.20775 16.4703 7.96883C16.2221 7.72991 15.929 7.5425 15.608 7.4174C15.287 7.2923 14.9444 7.232 14.6 7.23995H9.4C9.05724 7.23466 8.71679 7.29694 8.3981 7.42324C8.07942 7.54954 7.78873 7.73738 7.54265 7.97603C7.29656 8.21468 7.0999 8.49948 6.9639 8.81415C6.8279 9.12881 6.75521 9.46719 6.75 9.80996V15.5H8.75V22.91"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M12.0703 16.6201V23.0001"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
