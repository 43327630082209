import React from "react";

import { Button as ButtonStyle, RealButton } from "../../style/Button";
import { Link } from "gatsby";

const Button = (props) => {
  const { to, href, children, variant, onClick, borderRadius } = props;

  if (onClick && href) {
    return (
      <RealButton
        onClick={onClick}
        variant={variant}
        target="_blank"
        as="a"
        href={href}
        rel="noopener noreferrer"
        borderRadius={borderRadius || 0}
      >
        {children}
      </RealButton>
    );
  }

  if (onClick) {
    return (
      <RealButton
        onClick={onClick}
        variant={variant}
        borderRadius={borderRadius || 0}
      >
        {children}
      </RealButton>
    );
  }

  if (href) {
    if (href.substring(0, 4) !== "http") {
      return (
        <ButtonStyle
          as={Link}
          to={href}
          variant={variant}
          borderRadius={borderRadius || 0}
          {...props}
        >
          {children}
        </ButtonStyle>
      );
    }
    return (
      <ButtonStyle
        target="_blank"
        as="a"
        href={href}
        variant={variant}
        rel="noopener noreferrer"
        borderRadius={borderRadius || 0}
        {...props}
      >
        {children}
      </ButtonStyle>
    );
  }

  return (
    <ButtonStyle
      to={to}
      variant={variant}
      borderRadius={borderRadius || 0}
      {...props}
    >
      {children}
    </ButtonStyle>
  );
};

export default Button;
