import React from "react";

export const PlusIcon = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 7.99044H15" stroke="currentColor" />
    <path d="M7.99072 15V1" stroke="currentColor" />
  </svg>
);
