const breakpoints = ["576px", "768px", "992px", "1250px"];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const colors = {
  white: {
    100: "#FFF",
    70: "rgba(255,255,255,0.7)",
    50: "rgba(255,255,255,0.5)",
    60: "rgba(255,255,255,0.6)",
    15: "rgba(255,255,255,0.15)",
  },
  black: {
    100: "#000",
    80: "rgba(0,0,0,0.8)",
    60: "rgba(0,0,0,0.6)",
    50: "rgba(0,0,0,0.5)",
    40: "rgba(0,0,0,0.4)",
  },
  EYOffWhite: "#f6f6fa",
  EYOffBlack: {
    default: "#2e2e38",
    hover: "#101010",
    tile: "#1a1a24",
    text: "#292929",
  },
  EYGrey01: "#747480",
  EYYellow: {
    default: "#ffe600",
    hover: "#ffd600",
  },
  EYGrey02: {
    default: "#c4c4cd",
    65: "rgba(196,196,205,0.65)",
  },
};

const sizes = {
  mobileWidth: "91%",
  container: "1217px",
};

const times = {
  transition: "300ms",
};

const space = [
  "0",
  "4px",
  "8px",
  "16px",
  "32px",
  "64px",
  "128px",
  "256px",
  "512px",
];

const fontSizes = [
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "40px",
  "48px",
  "64px",
  "96px",
  "128px",
];

const lineHeights = [1, 1.125, 1.25, 1.5, 2];
lineHeights.heading = "64px";

const fontWeights = {
  light: 300,
  normal: 400,
  bold: 700,
};

const letterSpacings = {
  normal: "normal",
  caps: "0.25em",
  labels: "0.05em",
};

const radii = ["0", "2px", "4px", "8px", "16px"];

const shadows = {
  buttonYellow: `inset 0 0 0 2px ${colors.EYYellow.hover}`,
  buttonGray: `inset 0 0 0 2px ${colors.EYGrey01}`,
  buttonBlack: `inset 0 0 0 1px ${colors.EYOffBlack.default}`,
};

const borders = {
  borderToggle: `1px solid ${colors.EYGrey01}`,
  borderNone: "none",
};

export const theme = {
  name: "Default",
  breakpoints,
  colors,
  sizes,
  space,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacings,
  radii,
  times,
  shadows,
  borders,
};
