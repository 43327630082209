import styled from "styled-components";
import {
  space,
  color,
  layout,
  typography,
  flexbox,
  variant,
  position,
} from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

import { Link } from "gatsby";

const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: ${themeGet("fontWeights.bold")};
  transition: background-color ${themeGet("times.transition")};
  flex-shrink: 0;
  ${({ borderRadius }) =>
    borderRadius > 0 && `border-radius: ${borderRadius}px;`}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${flexbox}
  ${position}

  ${variant({
    variants: {
      fullYellow: {
        backgroundColor: "EYYellow.default",
        color: "EYOffBlack.default",
        border: "none",
        cursor: "pointer",
        "&:hover": {
          color: "EYOffBlack.default",
          backgroundColor: "EYYellow.hover",
        },
        "&:active, &:focus": {
          color: "EYOffBlack.default",
        },
      },
      outlineYellow: {
        backgroundColor: "transparent",
        boxShadow: "buttonYellow",
        color: "EYOffWhite",
        transition: "none",
        "&:hover": {
          color: "EYOffBlack.default",
          backgroundColor: "EYYellow.hover",
        },
        "&:active": {
          color: "EYOffBlack.default",
        },
      },
      outlineGray: {
        backgroundColor: "transparent",
        boxShadow: "buttonGray",
        color: "EYOffWhite",
        transition: "none",
        "&:hover": {
          color: "EYOffBlack.default",
          backgroundColor: "EYYellow.hover",
        },
        "&:active": {
          color: "EYOffBlack.default",
        },
      },
      outlineBlack: {
        backgroundColor: "transparent",
        boxShadow: "buttonBlack",
        border: "none",
        cursor: "pointer",
        color: "EYOffBlack.default",
        "&:hover": {
          color: "EYOffWhite",
          backgroundColor: "EYOffBlack.default",
        },
        "&:active, &:focus": {
          color: "EYOffBlack.default",
          outline: "none",
          "&:hover": {
            color: "EYOffWhite",
          },
        },
      },
      outlineNone: {
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "none",
        cursor: "pointer",
        color: "EYOffBlack.default",
        "&:hover": {
          color: "EYOffWhite",
          backgroundColor: "EYOffBlack.default",
        },
        "&:focus": {
          "&:hover": {
            color: "EYOffWhite",
          },
        },
        "&:active, &:focus": {
          color: "EYOffBlack.default",
          outline: "none",
        },
      },
    },
  })}
`;

Button.displayName = `Button`;

Button.defaultProps = {
  variant: "fullYellow",
  fontSize: "2",
  px: "43px",
  py: "0px",
  height: "48px",
};

Button.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
  ...propTypes.position,
};

const RealButton = Button.withComponent("button");
RealButton.defaultProps = {
  variant: "fullYellow",
  fontSize: "2",
  px: "43px",
  py: "0px",
  height: "48px",
};

export { Button, RealButton };
