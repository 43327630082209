import React from "react";

export const LinkedInIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <defs>
      <clipPath id="2yy6a">
        <path d="M20.01 18.01h19.978v18.988H20.01z" />
      </clipPath>
    </defs>
    <g>
      <g>
        <g>
          <path
            className="bg"
            fill="#0A66C1"
            d="M47.88 24c0 13.255-10.718 24-23.94 24C10.718 48 0 37.255 0 24S10.718 0 23.94 0c13.222 0 23.94 10.745 23.94 24"
          />
        </g>
        <g>
          <g>
            <g>
              <path
                className="inside"
                fill="#fff"
                d="M13.98 16c2.169 0 3.52-1.557 3.52-3.5-.04-1.987-1.351-3.5-3.48-3.5-2.128 0-3.52 1.513-3.52 3.5 0 1.943 1.35 3.5 3.439 3.5z"
              />
            </g>
            <g>
              <path
                className="inside"
                fill="#fff"
                d="M10.5 36.887h7.102V18H10.5z"
              />
            </g>
          </g>
          <g>
            <g />
            <g clipPath="url(#2yy6a)">
              <path
                className="inside"
                fill="#fff"
                d="M20.01 36.998h6.545v-10.36c0-.555.042-1.11.215-1.505.473-1.108 1.55-2.256 3.358-2.256 2.369 0 3.316 1.702 3.316 4.196v9.925h6.544V26.36c0-5.698-3.229-8.35-7.535-8.35-3.53 0-5.08 1.86-5.942 3.126h.044v-2.69H20.01c.086 1.74 0 18.552 0 18.552"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const LinkedInIcon2 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 20"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.48532 5C4.03501 5 5 3.88806 5 2.49982C4.97099 1.08057 4.03501 0 2.51468 0C0.993958 0 0 1.08057 0 2.49982C0 3.88806 0.964602 5 2.45631 5H2.48532Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 19.9193H5.07258V6.42822H0V19.9193Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.79199 20.0007H11.4667V12.6003C11.4667 12.2043 11.4972 11.8084 11.6208 11.5258C11.9585 10.7342 12.7279 9.91455 14.0191 9.91455C15.7111 9.91455 16.3874 11.1302 16.3874 12.9113V20.0007H21.0617V12.4023C21.0617 8.33203 18.7556 6.43799 15.6798 6.43799C13.1579 6.43799 12.0508 7.76642 11.4358 8.67094H11.4667V6.74894H6.79199C6.85339 7.99217 6.79199 20.0007 6.79199 20.0007"
      fill="currentColor"
    />
  </svg>
);
