import React from "react";

export const TriangleBottom = (props) => {
  return (
    <svg
      viewBox="0 0 531.74 460.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <polygon fill="currentColor" points="530.874,0.5 265.87,459.5 0.866,0.5 " />
    </svg>
  );
};
