import React from "react";

export const ArrowLeftIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M24 12.0143L3 12.0143" stroke="currentColor" />
    <path d="M11.25 20.25L2.25 12L11.25 3.75" stroke="currentColor" />
  </svg>
);

export const ArrowRightIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 11.9857H21" stroke="currentColor" />
    <path d="M12.75 3.75L21.75 12L12.75 20.25" stroke="currentColor" />
  </svg>
);
