import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

import { Flex, Container } from "../../style/Grid";
import { NavLink } from "../../style/NavLink";
import { Image } from "../../style/Image";
import { NavBar as NavBarStyle } from "../../style/NavBar";
import { ButtonIcon } from "../../style/ButtonIcon";
import { URL } from "../../constants";
import isActive from "../../utils/isActive";
import logo from "../../images/logo-ey.svg";
import menuButton from "../../images/menu-button.svg";
import menuButtonClose from "../../images/menu-button-close.svg";

const NavLinks = ({ mobile, location }) => {
  return (
    <>
      <NavLink
        to={URL.about}
        active={isActive(location.pathname, URL.about)}
        mobile={mobile}
        padding="50px"
      >
        O EY
      </NavLink>
      <NavLink
        to={URL.jobs}
        active={isActive(location.pathname, URL.jobs)}
        mobile={mobile}
      >
        Volné pozice
      </NavLink>
      <NavLink
        to={URL.blog}
        active={isActive(location.pathname, URL.blog)}
        mobile={mobile}
      >
        Články a podcasty
      </NavLink>
      <NavLink
        to={URL.education}
        active={isActive(location.pathname, URL.education)}
        mobile={mobile}
      >
        Vzdělávání
      </NavLink>
      <NavLink
        to={URL.events}
        active={isActive(location.pathname, URL.events)}
        mobile={mobile}
        pr="0"
        mr="0"
      >
        Potkej se s námi
      </NavLink>
    </>
  );
};

let lastScrollTop = 0;
const NavBar = ({ location, menuInverse = false }) => {
  const navBarRef = useRef(null);
  const [mobileMenuOpen, toggleMobileMenu] = useState(false);
  const [isMenuFixed, setMenuFixed] = useState(true);
  const [isMenuTop, setMenuTop] = useState(true);

  useEffect(() => {
    function handleScroll() {
      let delta = 5;
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (mobileMenuOpen) return;

      if (Math.abs(lastScrollTop - scrollTop) <= delta) return;

      setMenuTop(scrollTop < navBarRef.current.offsetHeight);

      if (scrollTop > 0 && scrollTop > lastScrollTop) {
        setMenuFixed(false);
      } else {
        if (scrollTop + window.innerHeight < document.body.clientHeight) {
          setMenuFixed(true);
        }
      }

      lastScrollTop = scrollTop;
    }

    window.addEventListener("scroll", handleScroll);

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <NavBarStyle
      ref={navBarRef}
      as="nav"
      role="navigation"
      aria-label="main-navigation"
      bg={
        isMenuTop && !menuInverse
          ? ["EYOffBlack.default", null, null, "transparent", null]
          : "EYOffBlack.default"
      }
      height={["64px", null, null, "80px", null]}
      justifyContent="center"
      position="fixed"
      top={isMenuFixed || menuInverse ? "0" : "-85px"}
    >
      <Container justifyContent="space-between" alignItems="center">
        <Link to="/" title="Logo">
          <Image src={logo} height={["36px", null, null, "auto", null]} />
        </Link>

        <Flex
          justifyContent="space-between"
          display={["none", null, null, "flex", null]}
          ml="auto"
        >
          <NavLinks mobile={false} location={location} />
        </Flex>

        <ButtonIcon
          onClick={() => {
            setMenuTop(false);
            toggleMobileMenu(!mobileMenuOpen);
          }}
          display={["flex", null, null, "none", null]}
          width="32px"
        >
          {mobileMenuOpen && <Image src={menuButtonClose} />}

          {!mobileMenuOpen && <Image src={menuButton} />}
        </ButtonIcon>

        {mobileMenuOpen && (
          <Flex
            bg="EYOffBlack.default"
            position="fixed"
            left="0"
            bottom="0"
            right="0"
            height="calc(100% - 64px)"
            borderTop="1px solid"
            borderTopColor="EYGrey01"
            flexDirection="column"
            pt="25px"
          >
            <NavLinks mobile={true} location={location} />
          </Flex>
        )}
      </Container>
    </NavBarStyle>
  );
};

export default NavBar;
