import styled from "styled-components";
import { space, flexbox, layout } from "styled-system";
import propTypes from "@styled-system/prop-types";

const ButtonIcon = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${flexbox}
  ${space}
  ${layout}
  
  &:focus {
    outline: none;
  }
`;

ButtonIcon.displayName = `ButtonIcon`;

ButtonIcon.defaultProps = {
  alignItems: "center",
  justifyContent: "center",
};

ButtonIcon.propTypes = {
  ...propTypes.space,
  ...propTypes.flexbox,
  ...propTypes.layout,
};

export { ButtonIcon };
