import React from "react";
import { Link } from "gatsby";

import { Wrapper, Container, Flex } from "../../style/Grid";
import { Image } from "../../style/Image";
import { Text } from "../../style/Text";
import { Link as StyledLink } from "../../style/Link";

import Icon from "../Icons";
import logo from "../../images/logo-ey-dark.svg";

import { URL } from "../../constants";
import { SocialLinks } from "../SocialLinks";
import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";

const StyledWrapper = styled(Wrapper)`
  @media screen and (max-width: ${themeGet("breakpoints.md")}) {
      ${(props) => props.hideFooterMobile && `display: none;`}
  }
`;

const Footer = ({ hideFooterMobile = false }) => (
  <StyledWrapper as="footer" mb="40px" mt={["40px", null, null, "130px", null]} hideFooterMobile={hideFooterMobile}>
    <Container flexDirection="column">
      <Flex
        borderBottomWidth={["0", null, null, "1px", null]}
        borderBottomStyle="solid"
        borderBottomColor="EYGrey02.default"
        pb={["0", null, null, "55px", null]}
        flexDirection={["column", null, null, "row", null]}
      >
        <Flex width={["100%", null, null, "35%", "40%"]}>
          <Link to="/" className="navbar-item" title="Logo">
            <Image src={logo} />
          </Link>
        </Flex>
        <Flex
          as="nav"
          width={["100%", null, null, "95%", "85%"]}
          flexDirection={["column", null, null, "row", null]}
          justifyContent="space-between"
          pt="10px"
        >
          <StyledLink
            to={URL.about}
            fontWeight="bold"
            mt={["15px", null, null, "0", null]}
            mb={["30px", null, null, "0", null]}
          >
            O EY
          </StyledLink>
          <StyledLink
            to={URL.jobs}
            fontWeight="bold"
            mb={["30px", null, null, "0", null]}
          >
            Volné pozice
          </StyledLink>
          <StyledLink
            to={URL.blog}
            fontWeight="bold"
            mb={["30px", null, null, "0", null]}
          >
            Články a podcasty
          </StyledLink>
          <StyledLink
            to={URL.events}
            fontWeight="bold"
            mb={["30px", null, null, "0", null]}
          >
            Potkej se s námi
          </StyledLink>
          <StyledLink
            to={URL.education}
            fontWeight="bold"
            mb={["30px", null, null, "0", null]}
          >
            Vzdělávání
          </StyledLink>
          <StyledLink to={URL.gdpr} fontWeight="bold">
            Ochrana osobních údajů
          </StyledLink>
        </Flex>
      </Flex>

      <Flex
        pt="45px"
        flexDirection={["column-reverse", null, null, "row", null]}
      >
        <Flex width={["100%", null, null, "50%", "60%"]} flexDirection="column">
          <Text fontSize="11px" color="black.80" fontWeight="light">
            Název EY zahrnuje celosvětovou organizaci a může zahrnovat jednu či
            více členských firem Ernst & Young Global Limited, z nichž každá je
            samostatnou právnickou osobou. Ernst & Young Global Limited, britská
            společnost s ručením omezeným garancí, služby klientům neposkytuje.
          </Text>
        </Flex>
        <SocialLinks
          width={["100%", null, null, "50%", "40%"]}
          justifyContent={["flex-start", null, null, "flex-end", null]}
          mb={["30px", null, null, "0", null]}
        />
      </Flex>
    </Container>
  </StyledWrapper>
);

export default Footer;
