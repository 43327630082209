import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Wrapper } from "../../style/Grid";

const NavBar = styled(Wrapper)`
  left: 0;
  z-index: 999;
  transition: all ${themeGet("times.transition")};
`;

NavBar.displayName = `NavBar`;

export { NavBar };
