import { useEffect } from "react";

function usePageView(pathname, pageCategory1, pageCategory2) {
  useEffect(() => {
    window.dataLayer.push({
      event: "ga.page",
      pagePath: pathname,
      pageCategory1: pageCategory1,
      pageCategory2: pageCategory2,
    });
  }, []);
}

export default usePageView;
