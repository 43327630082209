import { createGlobalStyle } from "styled-components";

import EYInterstateBoldWOFF from "../fonts/EYInterstateBold.woff";
import EYInterstateBoldWOFF2 from "../fonts/EYInterstateBold.woff2";

import EYInterstateRegularWOFF from "../fonts/EYInterstateRegular.woff";
import EYInterstateRegularWOFF2 from "../fonts/EYInterstateRegular.woff2";

import EYInterstateLightWOFF from "../fonts/EYInterstateLight.woff";
import EYInterstateLightWOFF2 from "../fonts/EYInterstateLight.woff2";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html,
  body {
    min-height: 100%;
    min-width: 320px;
    padding: 0;
    margin: 0;
    background-color: #fff;
  }

  .hidScrollbar::-webkit-scrollbar {
    display: none;
  }

  /*@font-face {
    font-family: "EYInterstate";
    font-style: normal;
    font-weight: 400;
    src: url(${EYInterstateRegularWOFF}) format("woff"), url(${EYInterstateRegularWOFF2}) format("woff2");
  }

  @font-face {
    font-family: "EYInterstate";
    font-style: normal;
    font-weight: 300;
    src: url(${EYInterstateLightWOFF}) format("woff"), url(${EYInterstateLightWOFF2}) format("woff2");
  }

  @font-face {
    font-family: "EYInterstate";
    font-style: normal;
    font-weight: 700;
    src: url(${EYInterstateBoldWOFF}) format("woff"), url(${EYInterstateBoldWOFF2}) format("woff2");
  }*/

  a#CybotCookiebotDialogPoweredbyCybot {
    display: none;
  }
  #CybotCookiebotDialogPoweredByText {
    display: none;
  }
`;

export default GlobalStyles;
