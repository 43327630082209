import styled, { css } from "styled-components";
import { space, layout, border, position, color } from "styled-system";
import propTypes from "@styled-system/prop-types";
import ImageComponent from "../../components/Image";

const Image = styled(ImageComponent)`
  margin: 0;
  padding: 0;
  transition: transform 0.25s;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  ${space}
  ${layout}
  ${border}
  ${position}
  ${color}
  ${({ cover }) =>
    cover &&
    css`
      width: 100%;
      height: 100%;
      position: absolute !important;
      left: 0 !important;
      top: 0 !important;
      object-fit: cover;
      object-position: center;
      z-index: 0 !important;
    `}
  ${({ autoHeight }) =>
    autoHeight &&
    css`
      height: auto !important;
      min-height: 100%;
    `}
  ${({ coverSVG }) =>
    coverSVG &&
    css`
      width: 100%;
      position: absolute !important;
      left: 0 !important;
      top: 0 !important;
      object-fit: cover;
      z-index: 0 !important;
    `}

  ${({ inCard }) =>
    inCard &&
    css`
      &::after {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #000000;
        opacity: 0.6;
      }
    `}
`;

Image.displayName = `Image`;

Image.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.border,
  ...propTypes.position,
  ...propTypes.color,
};

export { Image };
