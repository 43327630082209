import styled from "styled-components";

import Flex from "./Flex";

const Container = styled(Flex)``;

Container.displayName = `Container`;

Container.defaultProps = {
  width: ["100%", null, null, null, "container"],
  px: ["31px", null, null, null, 0],
};

export default Container;
