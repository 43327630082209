import React from "react";
import getStyledLink from "../../style/Link/getStyledLink";
import Icon from "../Icons";
import { Link as StyledLink } from "../../style/Link";

import { SocialLinks as SocialLinksStyle } from "../../style/SocialLinks";

export const SocialLinks = () => {
  const StyledLink = getStyledLink("a");
  return (
    <SocialLinksStyle
      width={["100%", null, null, "50%", "40%"]}
      justifyContent={["flex-start", null, null, "flex-end", null]}
      mb={["30px", null, null, "0", null]}
    >
      <StyledLink
        as="a"
        title="Facebook"
        href="https://www.facebook.com/EYCareersCzech"
        mr="3"
        height="48px"
        maxHeight={["14vw", "auto", null, null, null]}
      >
        <Icon name="facebook" type="colorful" />
      </StyledLink>
      <StyledLink
        as="a"
        title="Instagram"
        href="https://www.instagram.com/eyceskarepublika/"
        mr="3"
        height="48px"
      >
        <Icon name="instagram" />
      </StyledLink>
      <StyledLink
        as="a"
        title="Twitter"
        href="https://twitter.com/EY_CeskaRep"
        mr="3"
        height="48px"
      >
        <Icon name="twitter" />
      </StyledLink>
      <StyledLink
        as="a"
        title="Youtube"
        href="https://www.youtube.com/channel/UCOfL_0Rr5qQRN66_VoXylGA"
        mr="3"
        height="48px"
      >
        <Icon name="youtube" />
      </StyledLink>
      <StyledLink
        as="a"
        title="LinkedIn"
        href="https://www.linkedin.com/company/ernstandyoung"
        mr="3"
        height="48px"
      >
        <Icon name="linkedin" />
      </StyledLink>
    </SocialLinksStyle>
  );
};
