import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import { Wrapper, Container, Flex } from "../../style/Grid";
import { Text } from "../../style/Text";
import { Link as GatsbyLink } from "gatsby";
import { URL } from "../../constants";
import getStyledLink from "../../style/Link/getStyledLink";


const Link = getStyledLink(GatsbyLink);

const Cookies = () => {
  const [cookies, setCookies] = useState(undefined);

  useEffect(() => {
    setCookies(localStorage.getItem("cookies"));
  }, []);

  useEffect(() => {
    localStorage.setItem("cookies", cookies);
  }, [cookies]);

  const handleCookies = () => {
    setCookies("true");
  };

  if (cookies == "true") {
    return <></>;
  }

  return (
    <Wrapper
      position="fixed"
      bottom="0"
      left="0"
      zIndex="1050"
      backgroundColor="EYOffWhite"
    >
      <Container>
        <Flex
          background=""
          pt={["12px", null, null, "24px", null]}
          pb={["12px", null, null, "24px", null]}
          justifyContent="space-between"
          width="100%"
          flexDirection={["column", null, null, "row", null]}
        >
          <Text
            fontSize={["12px", null, "16px", null, null]}
            color="EYOffBlack.default"
            fontWeight="light"
            mb={["12px", null, null, 0, 0]}
          >
            Aby byla tvoje cesta s EY pohodlná, pracujeme na kariérním webu s Cookies.
            Používáním webových stránek s tím souhlasíš.{" "}
            <Link
              fontSize={["12px", null, "16px", null, null]}
              to={`${URL.gdpr}#cookies`}
              underline
            >
              Přečti&nbsp;si&nbsp;víc&nbsp;o&nbsp;souborech&nbsp;Cookies.
            </Link>
          </Text>
          <Button
            variant="outlineBlack"
            fontSize={["12px", null, "16px", "16px", "16px"]}
            color="EYOffBlack.default"
            onClick={handleCookies}
          >
            Rozumím a souhlasím.
          </Button>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default Cookies;
